<template>
  <div>
    
    <div class="row">
      
      <div class="col-3 my-3">
        <label for="">Catégorie</label>
        <select v-model="expense.title" class="form-select">
          <optgroup label="Dépenses de véhicules">
            <option value="Essence / diesel">Essence / diesel</option>
            <option value="Assurance">Assurance</option>
            <option value="Vignette">Vignette</option>
            <option value="Visite technique">Visite technique</option>
          </optgroup>
          <option value="Charges locatives">Charges locatives</option>
          <option value="Frais d'eau et d'électricité">Frais d'eau et d'électricité</option>
          <option value="Salaires">Salaires</option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for=""> Montant </label>
        <input type="text" v-model="expense.amount" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for=""> Date </label>
        <input type="date" v-model="expense.date" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for=""> Mode de paiement </label>
        <select v-model="expense.paymentMethod" class="form-select">
          <option value="Chèque">Chèque</option>
          <option value="Espèces">Espèces</option>
          <option value="Virement bancaire">Virement bancaire</option>
        </select>
      </div>


      <div  class="col-xl-3 col-12 my-3">
        <label for=""> Employee </label>
        <select
          
          v-model="expense.employee_reference"
          class="form-select"
        >
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for=""> Nº de Recu </label>
        <input type="text" v-model="expense.receiptNumber" class="form-control" />
      </div>

    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(expense)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      expense: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
     ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("expense/store", data);
    },
    async empty() {
      this.$store.commit("expense/setExpense", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("employee/getAll");
  },
};
</script>
